<template>
  <cz-dialog
    :title="dialogTitle"
    v-on="$listeners"
    v-bind="$attrs"
    :width="500"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form
        class="pa-6"
        @submit.prevent="$emit('submit', form)"
        :disabled="$attrs.loading"
      >
        <cz-form-field :label="$t('admin.categoryId')" required>
          <cz-input v-model="form.id" :error-messages="errors" readonly />
        </cz-form-field>
        <cz-form-field :label="$t('admin.categoryName')" required>
          <validation-provider
            :name="$t('admin.categoryName')"
            v-slot="{ errors }"
            rules="required"
          >
            <cz-input v-model="form.name" :error-messages="errors" />
          </validation-provider>
        </cz-form-field>

        <cz-button
          class="mt-6"
          type="submit"
          color="primary"
          :disabled="invalid || $attrs.loading"
          large
          :title="
            category ? $t('admin.updateCategory') : $t('admin.createCategory')
          "
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzFormField, CzButton, CzInput } from '@/components';
export default {
  name: 'VendorCategoryDialog',
  components: {
    CzDialog,
    CzFormField,
    CzButton,
    CzInput
  },
  props: {
    primaryCategoryId: {
      type: Number,
      default: undefined
    },
    categoryId: {
      type: Number,
      required: true
    },
    category: {
      type: Object
    }
  },
  computed: {
    dialogTitle() {
      if (this.primaryCategoryId) {
        if (!this.category) {
          return this.$t('admin.createNewSubCategory');
        } else {
          return this.$t('admin.updateExisitingSubCategory');
        }
      } else {
        if (!this.category) {
          return this.$t('admin.createNewCategory');
        } else {
          return this.$t('admin.updateExistingCategory');
        }
      }
    }
  },
  data() {
    return {
      form: {
        id: this.categoryId,
        name: this.category ? this.category.name : '',
        categoryId: this.primaryCategoryId
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <content-layout
    :loading="loading"
    :breadcrumbs-items="breadcrumbsItems"
    search-enabled
    :search-text.sync="searchText"
    :search-placeholder="$t('admin.searchCategoriesHint')"
  >
    <template #header>
      <v-toolbar flat color="background" class="mt-2">
        <cz-button
          :title="$t('admin.createNewCategory')"
          color="button"
          dark
          :icon-src="mdiPlusCircleOutline"
          @click="onAddNewCategory"
        />
      </v-toolbar>
    </template>
    <cz-data-table
      :columns="categoriesColumns"
      show-expand
      :expanded.sync="expanded"
      :items="categoriesToDisplay || []"
      height="calc(100vh - 300px)"
      item-key="_id"
    >
      <template #item.actions="{ item }">
        <cz-button :icon-src="mdiPencil" text @click="onEditCategory(item)" />
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-3">
          <v-card color="background" rounded="lg" outlined>
            <v-card-title>{{
              $t('admin.subCategories', [item.name])
            }}</v-card-title>
            <v-card-text>
              <cz-data-table
                :columns="categoriesColumns"
                hide-default-footer
                :items="
                  secondaryCategories.filter(
                    (c) => c.categoryId === item._id
                  ) || []
                "
                item-key="_id"
                max-height="600px"
              >
                <template #item.actions="{ item: subItem }">
                  <cz-button
                    :icon-src="mdiPencil"
                    text
                    @click="onEditSubCategory(subItem, item)"
                  />
                </template>
                <template #body.append="{}">
                  <div class="pa-6">
                    <cz-button
                      :title="$t('admin.createNewSubCategory')"
                      outlined
                      small
                      color="primary"
                      :icon-src="mdiPlusCircleOutline"
                      @click="onAddSubCategory(item)"
                    />
                  </div>
                </template>
              </cz-data-table>
            </v-card-text>
          </v-card>
        </td>
      </template>
    </cz-data-table>

    <vendor-category-dialog
      v-if="vendorCategoryDialog.show"
      v-model="vendorCategoryDialog.show"
      v-bind="vendorCategoryDialog"
      @close="vendorCategoryDialog.show = false"
      @submit="onSaveCategory"
    />
  </content-layout>
</template>

<script>
import ContentLayout from '@/layouts/ContentLayout';
import { CzDataTable, CzButton } from '@/components';
import { mapFields } from 'vuex-map-fields';
import { mdiPlusCircleOutline, mdiPencil } from '@mdi/js';
import VendorCategoryDialog from '../components/VendorCategoryDialog.vue';
import orderBy from 'lodash.orderby';
import {
  createVendorPrimaryCategory,
  updateVendorPrimaryCategory,
  createVendorSecondaryCategory,
  updateVendorSecondaryCategory
} from '@/core/api';
import { mapActions } from 'vuex';
export default {
  name: 'ManageVendorCategoriesPage',
  components: {
    ContentLayout,
    VendorCategoryDialog,
    CzDataTable,
    CzButton
  },
  computed: {
    ...mapFields('vendor', ['primaryCategories', 'secondaryCategories']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('admin.adminHomePage'),
          to: {
            name: 'super-admin-home'
          },
          disabled: false,
          exact: true
        },
        {
          text: this.$t('admin.vendorCategoriesTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    categoriesToDisplay() {
      if (!this.searchText) {
        return this.primaryCategories;
      } else {
        return this.primaryCategories.filter((c) =>
          c.name.includes(this.searchText)
        );
      }
    }
  },
  created() {},
  data() {
    return {
      mdiPencil,
      mdiPlusCircleOutline,
      expanded: [],
      searchText: '',
      categoriesColumns: [
        {
          text: this.$t('admin.categoryId'),
          value: '_id',
          sortable: true
        },
        {
          text: this.$t('admin.categoryName'),
          value: 'name',
          sortable: true
        },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          sortable: false
        }
      ],
      vendorCategoryDialog: {
        show: false,
        categoryId: '',
        loading: false,
        category: null,
        primaryCategoryId: undefined
      }
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    onAddNewCategory() {
      // calculate the category new id that needs to be added
      if (!this.primaryCategories?.length) {
        return;
      }
      const sortedCategories = orderBy(this.primaryCategories, '_id');
      const categoryId = sortedCategories[sortedCategories.length - 1]._id + 1;
      this.vendorCategoryDialog.category = null;
      this.vendorCategoryDialog.categoryId = categoryId;
      this.vendorCategoryDialog.show = true;
    },
    onEditCategory(category) {
      this.vendorCategoryDialog.categoryId = category._id;
      this.vendorCategoryDialog.category = category;
      this.vendorCategoryDialog.show = true;
    },
    onEditSubCategory(subCategory, category) {
      this.vendorCategoryDialog.primaryCategoryId = category._id;
      this.vendorCategoryDialog.categoryId = subCategory._id;
      this.vendorCategoryDialog.category = subCategory;
      this.vendorCategoryDialog.show = true;
    },
    onAddSubCategory(category) {
      this.vendorCategoryDialog.primaryCategoryId = category._id;
      // calculate the new sub category id
      const subCategories = orderBy(
        this.secondaryCategories.filter((c) => c.categoryId === category._id),
        '_id'
      );

      debugger;
      if (!subCategories?.length) {
        this.vendorCategoryDialog.categoryId = parseInt(`${category._id}1`);
      } else {
        const primaryCategoryString = String(
          this.vendorCategoryDialog.primaryCategoryId
        );
        const newestSubCategory = subCategories[subCategories.length - 1]._id;
        const newestSubCategoryId = String(newestSubCategory).substring(
          primaryCategoryString.length
        );
        const nextCategoryId = String(parseInt(newestSubCategoryId) + 1);
        const categoryId = parseInt(
          `${this.vendorCategoryDialog.primaryCategoryId}${nextCategoryId}`
        );
        this.vendorCategoryDialog.categoryId = categoryId;
      }

      this.vendorCategoryDialog.show = true;
    },
    onSaveCategory(item) {
      if (this.vendorCategoryDialog.primaryCategoryId) {
        this.saveSubCategory(item);
      } else {
        this.saveCategory(item);
      }
    },

    async saveCategory(category) {
      try {
        this.vendorCategoryDialog.loading = true;
        if (!this.vendorCategoryDialog.category) {
          const addedCategory = await createVendorPrimaryCategory({
            _id: category.id,
            name: category.name
          });

          this.primaryCategories.push(addedCategory);

          this.showSuccessToastMessage(
            this.$t('admin.categoryCreatedSuccess', [addedCategory.name])
          );
        } else {
          const updatedCategory = await updateVendorPrimaryCategory(
            category.id,
            category
          );

          const _categories = structuredClone(this.primaryCategories);

          const index = _categories.findIndex(
            (c) => c._id === updatedCategory._id
          );
          if (index !== -1) {
            _categories[index] = updatedCategory;
            this.primaryCategories = _categories;
          }

          this.showSuccessToastMessage(this.$t('admin.categoryUpdateSuccess'));
        }

        this.vendorCategoryDialog.show = false;
      } finally {
        this.vendorCategoryDialog.loading = false;
      }
    },
    async saveSubCategory(subCategory) {
      try {
        this.vendorCategoryDialog.loading = true;
        if (!this.vendorCategoryDialog.category) {
          const addedCategory = await createVendorSecondaryCategory({
            categoryId: this.vendorCategoryDialog.primaryCategoryId,
            _id: subCategory.id,
            name: subCategory.name
          });

          this.secondaryCategories.push(addedCategory);
          this.vendorCategoryDialog.show = false;

          this.showSuccessToastMessage(
            this.$t('admin.subCategoryCreatedSuccess', [addedCategory.name])
          );
        } else {
          const updatedCategory = await updateVendorSecondaryCategory(
            subCategory.id,
            subCategory
          );

          const _categories = structuredClone(this.secondaryCategories);

          const index = _categories.findIndex(
            (c) =>
              c._id === updatedCategory._id &&
              c.categoryId === this.vendorCategoryDialog.primaryCategoryId
          );
          if (index !== -1) {
            _categories[index] = updatedCategory;
            this.secondaryCategories = _categories;
          }

          this.showSuccessToastMessage(this.$t('admin.categoryUpdateSuccess'));

          this.vendorCategoryDialog.show = false;
        }
      } finally {
        this.vendorCategoryDialog.loading = false;
      }
    }
  },
  watch: {
    'vendorCategoryDialog.show': {
      handler(val, oldVal) {
        if (!val && oldVal) {
          this.vendorCategoryDialog.category = null;
          this.vendorCategoryDialog.categoryId = '';
          this.vendorCategoryDialog.loading = false;
          this.vendorCategoryDialog.primaryCategoryId = undefined;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
